<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Applications</h3>
        </div>
      </div>
      <div>
        <vs-row>
          <vs-col vs-w="4" class="p-2">
            <vs-card class="mt-6 bankCard">
              <div slot="header">
                <div class="items-center">
                  <h5 class="font-medium mb-2">Onboarding application</h5>
                </div>
              </div>
              <div>
                <div class="flex flex-col gap-y-4">
                  <p>Submission date: <span class="text-black">{{submissionDate}}</span></p>
                  <vs-button class="only-border-btn" size="medium" v-round @click="goToOnboardApplication">View application</vs-button>
                </div>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from 'moment'

export default {
  name: "ArchivedData",
  data() {
    return {
      bankAccounts: {},
      submissionDate: '',
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      checkFormDirty: false,
    };
  },
  methods: {
    ...mapActions('partnerDashboard',["fetchOnboardDetailByOnboardId"]),
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    goToOnboardApplication(){
      const routeData = this.$router.resolve({
        name: "onboard-application",
        params: {
          onboardingId: this.onboardingId
        }
      })
      window.open(routeData.href, '_blank');
    },

    async getOnboarDetail() {
      this.$vs.loading();
      const obId = this.user.userType === "admin" ? this.user.onboardingId: this.user.partner.onboardingId;
      if (obId && obId.length > 0) {
        await this.fetchOnboardDetailByOnboardId(obId)
        .then((response) => {
          this.submissionDate = moment(response.data.data.updatedAt).format("DD MMM yyyy");
          this.onboardingId = response.data.data._id;
          
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.showMessage(
            err.response.data.title || "Partner",
            err.response.data.message,
            "danger",
            "icon-check-circle"
          );
          if(err.response.status === 400) {
            this.$router.push({name: 'settings'});
          }
        });
      } else {
        this.$router.push({name: 'settings'});
      }
    },

    checkForm() {
      this.checkFormDirty = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    checkFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  mounted() {
    this.getOnboarDetail()
  },
};
</script>